@font-face {
  font-family: 'icomoon';
  src:  url('icomoon.eot?xix070');
  src:  url('icomoon.eot?xix070#iefix') format('embedded-opentype'),
    url('icomoon.ttf?xix070') format('truetype'),
    url('icomoon.woff?xix070') format('woff'),
    url('icomoon.svg?xix070#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-pdf:before {
  content: "\e926";
}
.icon-map:before {
  content: "\e948";
}
.icon-link:before {
  content: "\e9cb";
}
.icon-info:before {
  content: "\ea0c";
}
.icon-video:before {
  content: "\ea15";
}
.icon-email:before {
  content: "\e900";
}
