// Global variables

:root {
  //** Colors **//

  // Secondary color RGB values
  --red: 0 !important;
  --green: 115 !important;
  --blue: 168 !important;

  // Primary (color mostly used on site)
  --clr-primary: #fff;

  // Secondary (color for prominant call-to-action buttons, etc.)
  // --clr-secondary: rgb(0,115,168);
  --clr-secondary: rgb(var(--red), var(--green), var(--blue)); //#0073a8;

  // Tertiary (other color used on the site for accents, complimentary, etc.)
  --clr-tertiary: #0c2739;
  --clr-light: #eef1f2;

  // Dark
  --clr-darkest: #404040;

  // Light
  --clr-lightest: #fff;

  // Neutral tones of gray
  --clr-g1: #f8f8f8;
  --clr-g2: #eef2f3;
  --clr-g3: #e0e6e8;
  --clr-g4: #c5d0d3;
  --clr-g5: #9fa9ac;
  --clr-g6: #626c70;
  --clr-g7: #2f3739;

  --clr-yellow: #ffdf00;
  --clr-dark-yellow: #d9c016;

  // Background color
  --clr-background: var(--clr-light);

  // Link color
  --clr-link: var(--clr-secondary);

  //** Typography **//
  // Header XL
  --h-xl-family: Arial, sans-serif;
  --h-xl-weight: bold;
  --h-xl-size: 44px;
  // Header L
  --h-l-family: Arial, sans-serif;
  --h-l-weight: bold;
  --h-l-size: 34px;
  // Header M
  --h-m-family: Arial, sans-serif;
  --h-m-weight: bold;
  --h-m-size: 24px;
  // Header SM
  --h-sm-size: 18px;
  // Header XS
  --h-xs-size: 14px;
  // Paragraph L
  --p-m-family: Arial, sans-serif;
  --p-m-weight: normal;
  --p-m-size: 24px;
  // Paragraph M
  --p-m-family: Arial, sans-serif;
  --p-m-weight: normal;
  --p-m-size: 18px;
  // Paragraph SM
  --p-sm-size: 16px;
  // Paragraph XS
  --p-xs-size: 14px;
  // Paragraph XXS
  --p-xxs-size: 12px;
  // Paragraph XS
  --p-xxxs-size: 10px;

  //** Border-radii **//
  --br-s: 4px;
  --br-m: 8px;

  //** Box-shadow **//
  --bs-s: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%),
    0 1px 5px 0 rgb(0 0 0 / 12%);
  --bs-m: 0 3px 2px rgba(0, 0, 0, 0.25), 0 3px 3px rgb(0 0 0 / 14%);
  --bs-l: 0 7px 14px rgba(0, 0, 0, 0.25), 0 5px 5px rgba(0, 0, 0, 0.22);
}

// Reset
*:focus {
  outline: none;
}

// Base styles

body {
  background-color: var(--clr-background) !important;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
}

.modal-dialog {
  display: flex; 
  justify-content: center; 
  align-items: center;
  margin: 0 auto !important;
  height: 100vh;
}

.image-modal-dialog {
  max-width: fit-content !important;
}
